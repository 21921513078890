<template>
  <section class="section suggestion">
    <h1>{{ $t(brand) }}</h1>
    <div class="suggestion-text">
      <p class="text-h1">{{ $t(`suggestion_section.title.${page}`) }}</p>
      <p v-if="isStatistics && levelPlatinum" class="suggestion__statistics">
        {{ $t("facility", levelPlatinum.facility_amount) }}
        {{ $t("activity", levelPlatinum.activity_amount) }}
      </p>
      <p v-if="page !== User.members" class="text-h1 suggestion-text__subtitle">
        {{ $t(`suggestion_section.subtitle.${page}`) }}
      </p>
      <p class="suggestion__description text-h4">
        {{ $t(`suggestion_section.description.${page}`) }}
      </p>
      <BaseButton @click="showModalWrapper">
        {{ $t(wayToGetOfferText) }}
      </BaseButton>
    </div>
    <div class="suggestion-images">
      <slot name="images"></slot>
    </div>
  </section>
</template>

<script lang="ts">
import { PropType } from "vue";

import { CooperationTypes, User } from "~/types/entities";

import BaseIcon from "~/components/icons/BaseIcon.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import { SuggestionEvents } from "~/utils/analytics";

import { getStatistics } from "~/api/statisticsRequest";
import { useGetAnOfferFormModal } from "~/composables/modals/getAnOfferFormModal/useGetAnOfferFormModal";

export default defineNuxtComponent({
  components: {
    BaseIcon,
    BaseButton,
  },
  props: {
    page: {
      type: String as PropType<User>,
      required: true,
    },
    isStatistics: {
      type: Boolean,
      default: false,
    },
  },
  async setup(props) {
    const { locale } = useI18n();
    const { gtag } = useGtag();
    const { brand } = useRuntimeConfig().public;
    const route = useRoute();
    const localePath = useLocalePath();
    const { showModal } = useGetAnOfferFormModal();

    const {
      levels: { platinum: levelPlatinum },
    } = await getStatistics(locale.value);

    const cooperationType = computed(() => {
      if (route.fullPath === localePath("/companies")) return CooperationTypes.CONNECT_COMPANY;
      else if (route.fullPath === localePath("/partners")) return CooperationTypes.PARTNER;
      else return undefined;
    });

    const showModalWrapper = () => {
      gtag("event", SuggestionEvents.getOfferModal);
      showModal(true, cooperationType.value);
    };

    const wayToGetOfferText = computed(() => props.page === User.partners ? "Become a Partner" : "Get an Offer");

    return {
      brand,
      showModal,
      showModalWrapper,
      CooperationTypes,
      User,
      levelPlatinum,
      wayToGetOfferText,
    };
  },
});
</script>
